import React from "react"
import { Link } from "gatsby"

const IndexPage = () => (
  <>
    <h1></h1>
  </>
)

export default IndexPage
